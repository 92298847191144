<div class="close-Area"> 
    <mat-icon class="pull-right" mat-dialog-close>close</mat-icon>
</div>

<div class="success" *ngIf="iSuccess">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Piggy Bank.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont mt-20 f-24 fw-900 ls">Congratulations!</div>
            <div class="mt-12 semifont f-19 fw-900">Your investment will reflect </div>
            <div class="mt-12 semifont f-19 fw-900">in your portfolio within 24hours.</div> -->
            <p class="mt-30 f-28 fw-900">Congratulations!</p>
            <p class="mt-30 f-26 addlh">We have received your investment will be reflected in your portfolio in within 24 hours. </p>
            <div class="mt-20 f-20"></div>
            <!-- <div class="mt-20">Do  not close this window. You will be redirected to<br/> your dashboard</div> -->
        </div>
    </div>
</div>

<div class="success" *ngIf="success">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Piggy Bank.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont mt-20 f-24 fw-900 ls">Congratulations!</div>
            <div class="mt-12 semifont f-19 fw-900">Your investment will reflect </div>
            <div class="mt-12 semifont f-19 fw-900">in your portfolio within 24hours.</div> -->
            <p class="mt-30 f-28 fw-900">Success</p>
            <p class="mt-30 f-26 addlh">{{data.message}}</p>
            <div class="mt-20 f-20"></div>
            <!-- <div class="mt-20">Do  not close this window. You will be redirected to<br/> your dashboard</div> -->
        </div>
    </div>
</div>

<div class="failure" *ngIf="iFailure">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Error.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont mt-20 f-24 fw-900 ls">Something went wrong</div>
            <div class="mt-12 semifont f-19 fw-900 lh">Your payment was Unsuccessful. Please try again in sometime.</div> -->
            <!-- <div class="mt-20 semifont f-19 fw-900 lh">For any issues, Email us at<br/>info@creditfaircapital.in</div> -->

            <p class="mt-30 f-28 fw-900 errormsg">Something went wrong</p>
            <p class="mt-30 f-26 addlh">Your payment was Unsuccessful. Please try again in sometime.</p>
            <div class="mt-20 f-20">For any issues, email us at<br/>info@creditfaircapital.in</div>
        </div>
    </div>
</div>

<div class="withdrawl-success" *ngIf="wSuccess">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Investment.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont f-19 fw-900 popup-text">You have successfully made a withdrawl from  
            your portfolio. The  amount withdrawn will reflect
            in your selected Bank Account within 24 hours.</div>  -->

            <p class="mt-30 f-28 fw-900">Thank you</p>
            <p class="mt-30 f-26 addlh">Your withdrawal request has been accepted. The requested amount will be transfered to your selected bank account within 2 working days. </p>
            <div class="mt-20 f-20"></div>
        </div>
    </div>
</div>

<div class="error" *ngIf="error">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Error.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont f-19 fw-900">{{this.data.title}}</div> -->
            <!-- <div class="mt-12 semifont f-19 fw-900">your portfolio. The  amount withdrawn will reflect</div> -->
            <!-- <div class="mt-12 semifont f-19 fw-900">in your selected Bank Account within 24 hours.</div>  -->
            <div class="mt-30 semifont f-24 fw-900" style="line-height: 26px;">{{this.data.message}}</div> 

            <!-- <p class="mt-30 f-28 fw-900 errormsg">Something went wrong</p>
            <div class="mt-20 f-20">Try again later.</div> -->
            <!-- <p class="mt-30 f-26 addlh">{{this.data.title}}</p> -->
            <!-- <div class="mt-20 f-20">{{this.data.message}}</div> -->
        </div>
    </div>
</div>

<div  *ngIf="nomSuccess">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Congratulations.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont mt-20 f-24 fw-900 ls">Congratulations!</div>
            <div class="mt-12 semifont f-19 fw-900 ">Added Nominee Successfully</div> -->

            <p class="mt-30 f-28 fw-900">Congratulations!</p>
            <p class="mt-30 f-26 addlh">Nominee added successfully.</p>
            <div class="mt-20 f-20"></div>
        </div>
    </div>
</div>

<div  *ngIf="nomFailure">
    <div class="text-center cpopup">
        <div>
            <img src="../../../../assets/images/icons/Error.png" width="100" height="100" />
            <!-- <div class="mt-12 semifont mt-20 f-24 fw-900 ls">Failure</div>
            <div class="mt-12 semifont f-19 fw-900 ">Failed to add Nominee</div> -->
            <p class="mt-30 f-28 fw-900">Oops..!</p>
            <p class="mt-30 f-26 addlh">There was an error in adding the nominee.</p>
            <div class="mt-20 f-20"></div>
        </div>
    </div>
</div>
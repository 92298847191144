import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { AllMaterialModule } from './material-module';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from 'src/app/store/reducers';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { AuthInterceptor } from 'src/app/core/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { MonthYearDirective } from './common/directives/month-year.directive';
import { CryptoService } from './services/crypto.service';
import { BlankComponent } from './blank/blank.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TncComponent } from './tnc/tnc.component';
import { CookieService } from 'src/app/services/cookie.service';
import { PaymentConfirmationComponent } from 'src/app/payment-confirmation/payment-confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InvalidResetLinkComponent } from './invalid-reset-link/invalid-reset-link.component';
import { ModalwindowModule } from 'src/app/modules/shared/modal-window/modal-window-model';

@NgModule({
  declarations: [
    AppComponent, 
    HeaderComponent, 
    FooterComponent, 
    BlankComponent, 
    PrivacyPolicyComponent, 
    TncComponent,
    PaymentConfirmationComponent,
    ResetPasswordComponent,
    InvalidResetLinkComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalwindowModule,
    BrowserAnimationsModule,
    AllMaterialModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService, CryptoService, CookieService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

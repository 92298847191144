import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'src/app/services/cookie.service';
import { OnboardingService } from '../modules/onbording/services/onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
   private userInfo:any;
   private docs:any;
   private accordionControls:any = [{id:'personal_details',disabled:true,show:true,visited:false,fields:['pan_number',]},
    {id:'address',disabled:true,show:true,visited:false,fields:['pincode']},
    {id:'bank_details',disabled:true,visited:false,show:true,fields:['account_number']},
    {id:'documents',disabled:true,visited:false,show:true,fields:[]},
    {id:'signed_agreement',disabled:true,visited:false,show:true,fields:['agreement_link']}]

  constructor(private router: Router,
    private onBoardingService : OnboardingService){} 

 async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
            const user:any = await this.onBoardingService.getUser().toPromise();
            if( user){
                this.userInfo =(user.data)? user.data:user;
                this.docs = this.userInfo.documents;
                let keys = Object.keys(this.userInfo);
                for(let accordion of  this.accordionControls){
                  if(keys.indexOf(accordion.id)!= -1){
                      accordion.disabled = false;
                    if(this.userInfo[accordion.id]==null || this.userInfo[accordion.id] == undefined){
                        accordion.show = false;
                        accordion.active = true;
                        break;
                    }
                    let unfilledField =  accordion.fields.find((field:any)=>  this.userInfo[accordion.id][field]== undefined || this.userInfo[accordion.id][field]=='' );
                    if(unfilledField && unfilledField == 'pan_number'){
                      accordion.show = false;
                      accordion.active = true;
                      break;
                    }
                    if(unfilledField && unfilledField == 'pincode'){
                      accordion.show = false;
                      accordion.active = true;
                      break;
                    }            
                    if(unfilledField && unfilledField == 'account_number'){
                      accordion.show = false;
                      accordion.active = true;
                      break;
                    }            
                    if(accordion.id == 'documents' &&  this.userInfo['documents'].length <= 4){
                      accordion.show = false;
                      accordion.active = true;
                      break;
                    }
                     if(unfilledField && unfilledField == 'agreement_link'){
                        accordion.show = false;
                        accordion.active = true;
                        break;
                      }else{
                        accordion.visited = true;
                      }
                  }else{
                    accordion.disabled = false;
                    accordion.show = false;
                    accordion.active = true;
                    break;
                  }
                }
                let isAllAccordionVisited = user.data.personal_details && user.data.personal_details.is_existing ?
                                            user.data.personal_details.is_existing : 
                                            this.accordionControls.filter((accordion:any)=> accordion.visited).length== 5
                if(isAllAccordionVisited){
                   // this.router.navigateByUrl('/dashboard');
                    return true;
                }else{
                  this.router.navigate(['/onboarding']);
                    return false;
                }
            } 
            this.router.navigate(['/onboarding']);
            return false;
    }
  
}

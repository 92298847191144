<div class="footer-wrapper dgrey-bg pb-50" [hidden]="footerHide()">
    <div class="container pt-100 pb-150 bb">
        <div class="row m-auto white-text">
            <div class="col-md-6">
                <ul class="f-13">
                    <span class="yellow-text f-26 boldfont fw-700">Contact Us</span>
                    <!-- <li class="w120"><img src="../../assets/images/footer-logo.jpg" /></li> -->
                    <li class="mt-20">902, Tower A, Naman Midtown, <br/>Senapati Bapat Marg, Mumbai 400013<br/> Phone: +918097400463<br/> Email: Info@creditfair.in</li>
                </ul>
            </div>
            <!-- <div class="col-md-3">
                <span class="yellow-text f-26 boldfont fw-700">Quick Links</span>
                <ul class="caps mt-45">
                    <li>About</li>
                    <li class="pt-10">Products</li>
                    <li class="pt-10">Careers</li>
                    <li class="pt-10">Contact us</li>
                </ul>
            </div> -->
            <div class="col-md-6 f-14 madd">
                <span class="yellow-text f-26 boldfont fw-700">Legal</span>
                <div class="mt-20">
                    <!-- <li class="mt-40">Cancellation and Refund Policy</li>
                <li>Agreements</li>
                <li>Fair Practice Code</li> -->
                <li (click)="privacyPolicy()" class="cPointer">Privacy Policy</li>
                <li (click)="terms()" class="cPointer">Terms of use</li>
                <!-- <li>KYC Policy</li>
                <li>Outsourceing Policy</li> -->
                <!-- <li (click)="faq()" class="cPointer">FAQs</li> -->
                </div>
                
            </div>
            <!-- <div class="col-md-3">
                <span class="yellow-text f-26 boldfont fw-700">Blog</span>
                <div class="mt-40">
                    <span class="f-14">Subscribe to our blog to get updates</span>
                    <mat-form-field appearance="outline" style="display: flex !important; margin-top: 30px;" class="w100">
                        <div style="margin: 12px 0px; width: 195px;">
                            <input matInput placeholder="Email Address">
                        </div>
                        <div class="arrow">
                            <img src="../../assets/images/right-arrow.png" />
                            <mat-icon matSuffix>keyboard_arrow_right</mat-icon>
                       </div>                    
                    </mat-form-field> 
                </div>
            </div> -->
        </div>
    </div>   
    <div class="container white-text f-12 pt-20">
        <div>@ 2022 <span class="fw-600">Credit Fair Capital.</span> All rights reserved</div>
        <div class="mt-10">RBI registration number:<span class="fw-600"> N.13-02278</span></div>
        <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disclaimer: a) “Reserve Bank of India neither accepts any responsibility 
            nor guarantees the present position as to the financial soundness of the 
            company or for the correctness of any of the statements or 
            representations made or opinions expressed by the company and for 
            discharge of any liability by the company.” b) “Neither there is any 
            provision in law to keep, nor does the company keep any part of the 
            deposits with the Reserve Bank of India and by issuing a Certificate of 
            Registration to the company, the Reserve Bank of India, neither accepts 
            any responsibility nor guarantees the payment of deposits to any 
            4.
            5.
            depositor or any person who has lent any sum to the company.”</div>
    </div>   
</div>

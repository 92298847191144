import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private VALIDATE_PAN = 'api/validate-pan';
  private CREATE_USER = 'api/create-user';
  private ADD_BANK_DETAILS = 'api/bank';
  private VALIDATE_BANK_DETAILS = 'api/validate-bank';
  private ADD_ADDRESS = 'api/update-address';
  private ADD_NOMINEE = 'api/add-nominee';
  private UPLOAD_AGGREMENT = 'api/esign-agreement';
  private GET_USER = 'api/get-user';
  private GET_PINCODE_DETAILS = 'api/get-pincode-details';
  private GET_AGREEMENT = 'api/get-agreement-link';
  private GET_INVESTOR_DETAILS = 'investor/get-investor-details'
  
  constructor(private http: HttpClient) { }

  validatePAN(pan : string){
    const data = {
      pan: pan
    }
    return this.http.post(environment.base_url + this.VALIDATE_PAN, data);
  }

  registerUser(data : any){
    return this.http.post(environment.base_url + this.CREATE_USER, data);
  }
  
  addBankDetails(data : any){
    return this.http.post(environment.base_url + this.ADD_BANK_DETAILS, data);
  }
  
  validateBankDetails(data : any){
    return this.http.post(environment.base_url + this.VALIDATE_BANK_DETAILS, data);
  }
  
  addAddress(data : any){
    return this.http.post(environment.base_url + this.ADD_ADDRESS, data);
  }
  
  addNominee(data : any){
    return this.http.post(environment.base_url + this.ADD_NOMINEE, data);
  }
  
  uploadAggrement(data : any){
    return this.http.post(environment.base_url + this.UPLOAD_AGGREMENT, data);
  }

  getAgreement(){
    return this.http.get(environment.base_url + this.GET_AGREEMENT, {responseType : 'text'});
  }
  
  getInvestorAgreement(investorId :any){
    return this.http.get(environment.base_url + this.GET_AGREEMENT + "?id=" + investorId, {responseType : 'text'});
  }
  
  getUser(){
    return this.http.get(environment.base_url + this.GET_USER);
  }
  
  getInvestorProfile(id : any){
    return this.http.get(environment.base_url + this.GET_INVESTOR_DETAILS + '?id='+id).toPromise();
  }

  fetchPincodeDetails(data : any){
    
    return this.http.get(environment.base_url + this.GET_PINCODE_DETAILS + '?pincode=' + data.pincode );
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
accordianOpenHandler: any = new Subject <any> ();

  constructor(private http:HttpClient) { }

  
  paymentConfirmation(
    razorpay_payment_id:string , 
    razorpay_payment_link_id:string,  
    razorpay_payment_link_reference_id:string, 
    razorpay_payment_link_status:string,
    razorpay_signature:string){
    const data = {
      "razorpay_payment_id": razorpay_payment_id,
      "razorpay_payment_link_id": razorpay_payment_link_id,
      "razorpay_payment_link_reference_id": razorpay_payment_link_reference_id,
      "razorpay_payment_link_status": razorpay_payment_link_status,
      "razorpay_signature": razorpay_signature
  };
  return this.http.post(environment.base_url+'payment/payment-confirmation', data).toPromise();
  }


}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  public message !:string;
  public password:string = environment.password;
  public encryptedMessage !: string;
  public decryptedMessage !: string;

  constructor() { }

  encrypt(token:string){
    this.message=token;
    this.encryptedMessage = CryptoJS.AES.encrypt(this.message.trim(),this.password.trim()).toString();
    return this.encryptedMessage;
  }

  decrypt(token:any, key:any = this.password.trim()){
    this.message = token;
    this.decryptedMessage = CryptoJS.AES.decrypt(this.message, key).toString(CryptoJS.enc.Utf8);
    return this.decryptedMessage;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  
  onUserUpdate: Subject<any> = new Subject();

  constructor() { }

  public static getSystemDetails(userAgent=navigator.userAgent){
    let unknown = '-';
    let defaultBrowser = 'Unknown Browser';
    
    // browser
    let nVer = navigator.appVersion;
    let browser = navigator.appName;
    let nAgt = userAgent;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion:any = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;
     // Opera
     if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
          version = nAgt.substring(verOffset + 8);
      }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
  }
  // Edge
  else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
          version = nAgt.substring(verOffset + 8);
      }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
  }
  // Other browsers
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browser = defaultBrowser;//nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      /*if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
      }*/
  }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);
    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = ''+parseInt(navigator.appVersion, 10);
    }

    if(browser == defaultBrowser){
      majorVersion = "";
    }
    // mobile version
    let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
    // cookie
    let cookieEnabled = (navigator.cookieEnabled) ? true : false;
    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
    }
    // system
    let os = unknown;
    let clientStrings = [
    {s:'Windows', r:/((Windows)( NT)?) (([78]|\d\d)([.]\d+)?|[^2-69].\d|6.[1-3])/},
        {s:'Windows Vista', r:/Windows NT 6.0/},
        {s:'Windows Server 2003', r:/Windows NT 5.2/},
        {s:'Windows XP', r:/(Windows (XP|NT 5.1))/},
        {s:'Windows 2000', r:/(Windows (2000|NT 5.0))/},
        {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
        {s:'Windows 98', r:/(Win(dows )?98)/},
        {s:'Windows 95', r:/(Win(dows[\s_])?95)/},
        {s:'Windows NT 4.0', r:/(Win(dows\s?)?NT(\s?4.0)?)/},
        {s:'Windows CE', r:/Windows CE/},
        {s:'Windows Phone', r:/Windows Phone/},
    {s:'Windows 3.11', r:/Win16/},
        {s:'Open BSD', r:/OpenBSD/},
        {s:'Sun OS', r:/SunOS/},
        {s:'Chrome OS', r:/CrOS/},
        /*{s:'Linux', r:/(Linux|X11(?!.*CrOS))/},*/
    {s:'Linux', r:/(Ubuntu|X11(?!.*CrOS))/},
        {s:'iOS', r:/(iPhone|iPad|iPod)/},
        {s:'Mac OS X', r:/Mac OS X/},
        {s:'Mac OS', r:/(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
        {s:'QNX', r:/QNX/},
        {s:'UNIX', r:/UNIX/},
        {s:'BeOS', r:/BeOS/},
        {s:'OS/2', r:/OS\/2/},
        {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/},
    {s:'CrKey', r:/CrKey/},
    {s:'Android', r:/Android/},
    ];
    for (let id in clientStrings) {
        let cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }
    var osVersion = "";
    var matches = /((Windows Phone|Chrome|NT|Mac OS|Mac|Linux|CrOS|armv7l|Android)[\s]*) ([(\(.|x|X|_|\d)\(\(.|x|X|_|\d)\(\(.|x|X|_|\d))]+)/.exec(nAgt)
    osVersion = matches && matches.length > 2 ? matches[3] : "";
    osVersion = osVersion.replace (/[)]/g, "");
   
    let obj:any = {};
  
    obj["browser"] = browser;
    obj["browserVersion"] = version;
    obj["browserMajorVersion"] = majorVersion;
    obj["mobile"] = mobile;
    obj["os"] = os;
    obj["osVersion"] = osVersion;
    obj["userAgent"] = userAgent;
    return obj;
  }
  
  public states(){
      return [{"code": "AN","name": "Andaman and Nicobar Islands"},
      {"code": "AP","name": "Andhra Pradesh"},
      {"code": "AR","name": "Arunachal Pradesh"},
      {"code": "AS","name": "Assam"},
      {"code": "BR","name": "Bihar"},
      {"code": "CG","name": "Chandigarh"},
      {"code": "CH","name": "Chhattisgarh"},
      {"code": "DH","name": "Dadra and Nagar Haveli"},
      {"code": "DD","name": "Daman and Diu"},
      {"code": "DL","name": "Delhi"},
      {"code": "GA","name": "Goa"},
      {"code": "GJ","name": "Gujarat"},
      {"code": "HR","name": "Haryana"},
      {"code": "HP","name": "Himachal Pradesh"},
      {"code": "JK","name": "Jammu and Kashmir"},
      {"code": "JH","name": "Jharkhand"},
      {"code": "KA","name": "Karnataka"},
      {"code": "KL","name": "Kerala"},
      {"code": "LD","name": "Lakshadweep"},
      {"code": "MP","name": "Madhya Pradesh"},
      {"code": "MH","name": "Maharashtra"},
      {"code": "MN","name": "Manipur"},
      {"code": "ML","name": "Meghalaya"},
      {"code": "MZ","name": "Mizoram"},
      {"code": "NL","name": "Nagaland"},
      {"code": "OR","name": "Odisha"},
      {"code": "PY","name": "Puducherry"},
      {"code": "PB","name": "Punjab"},
      {"code": "RJ","name": "Rajasthan"},
      {"code": "SK","name": "Sikkim"},
      {"code": "TN","name": "Tamil Nadu"},
      {"code": "TS","name": "Telangana"},
      {"code": "TR","name": "Tripura"},
      {"code": "UK","name": "Uttarakhand"},
      {"code": "UP","name": "Uttar Pradesh"},
      {"code": "WB","name": "West Bengal"}];
  }
}

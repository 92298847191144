import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalWindowService } from './modal-window-service';

@Component({
  selector: 'creditfaircapital-modal', 
  // selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalWindowComponent implements OnInit, OnDestroy {
  @Input() id: any | undefined;
  private element: any;
  currentUrl: string | undefined;

  constructor(private modalService: ModalWindowService,private router: Router, private el: ElementRef) {
      this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url
      // ensure id attribute exists
      if (!this.id) {
          console.error('modal must have an id'); 
          return;
      }

      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      document.body.appendChild(this.element);

      // close modal on background click
      this.element.addEventListener('click', (el: { target: { className: string; }; }) => {
          if (el.target.className === 'creditfaircapital-modal') {
            if (!window.location.href.includes('login'))
            {
                this.close();
            } 
          }
      });

      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
  }

  // open modal
  open(): void {
      this.element.style.display = 'block';
      document.body.classList.add('creditfaircapital-modal-open');
  }

  // close modal
  close(): void {
      this.element.style.display = 'none';
      document.body.classList.remove('creditfaircapital-modal-open');
  }
}
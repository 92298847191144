<div *ngIf="isValid && !showInvalidResetLink" class="login-wrapper login">
    <div class="login-inner reset-pw">
        <p class="voilet-text fw-900 f-26 text-center">Reset Password</p>
        <div class="w90 m-auto mt-30">
            <form [formGroup]="form" class="example-form">
                <div>
                    <p class="f-16 regularfont greyfont">New Password</p>
                    <mat-form-field class="example-full-width" appearance="outline" class="w100">
                        <input formControlName="password" required matInput autocomplete="off" style="text-align: left;" type="password">
                        <mat-error *ngIf="resetForm['password'].hasError('required')">
                            New Password is required
                        </mat-error>
                        <mat-error *ngIf="resetForm['password'].hasError('pattern')">
                            New Password must be at least 6 characters and should include a combination of upper 
                            and lower case letters, numbers and special character.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <p class="f-16 regularfont greyfont">Confirm Password</p>
                    <mat-form-field class="example-full-width" appearance="outline" class="w100">
                        <input formControlName="confirmPassword" required matInput autocomplete="off" style="text-align: left;"  type="password">
                        <mat-error *ngIf="resetForm['confirmPassword'].hasError('required')">
                            Confirm Password is required
                        </mat-error>
                        <mat-error *ngIf="resetForm['confirmPassword'].touched && resetForm['confirmPassword'].invalid && resetForm['password'].touched">
                            New Passsword and
                        Confirm Password didn't match. 
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row text-center login-btn mt-30 mview">
                    <div class="col-md-12 btn-style" style="justify-content: center;">
                        <div class="col-md-6 pwbtn" style="padding-left: 0;margin-right: 15px;">
                            <button mat-raised-button color="primary" type="button" class="f-18 cPointer" 
                            (click)="submit()">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div *ngIf="!isValid && showInvalidResetLink"  class="login-wrapper login">
    <div class="login-inner">
        <div class="w90 m-auto mt-30">
            <div class="w90 m-auto mt-30">
                <div class="">
                    <div class="text-center cpopup">
                        <div>
                            <img src="../../../assets/images/icons/Error.png" width="80" height="80" />
                            <p class="mt-30 f-28 fw-900 addlh">Link expired or has already been used.</p>
                            <p class="mt-30 f-26 addlh">To reset your password, return to the login page and select "Reset Password" to send a new email. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
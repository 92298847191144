<div class="tnc-container">
    <div class="row">
        <div class="col-md-12">
            <div>
                <h1>TERMS OF USE</h1>
                <h2>Introduction</h2>
                <p>
                    Thank you for using <a href="https://creditfaircapital.in" class="link">creditfaircapital.in</a>(the “Website”). These terms of use govern
                    your use of our site. Please read these terms in full before you use this website. If
                    You Do Not Wish To Be Bound By These Terms Of Use, You May Not Access Or Use
                    The Website . Your continued use of this site confirms your acceptance of these
                    terms. Please note separate advertising terms and conditions are available upon
                    request. These Terms of Use are provided on behalf of K.M. Global Financial Services
                    Private Limited.
                </p>
                <h2>Privacy</h2>
                <p>
                    Your privacy is very important to us. Our privacy policy explains how we treat your
                    personal information and protect your privacy when you use our Website. We will
                    not share or sell any of your information with any third party, except as specifically
                    described in our privacy policy, which is hereby incorporated by reference into these
                    Terms of Use.
                </p>
                <h2>Intellectual Property Rights</h2>
                <p>
                    Unless otherwise stated, we or our licensors own the intellectual property rights in
                    the website and material on the website. Subject to the licence below, all our
                    intellectual property rights are reserved.
                </p>
                <h2>Site Time Up</h2>
                <p>
                    All reasonable measures are taken by us to ensure that this website is operational all
                    day, every day. However, occasionally technical issues may result in some downtime
                    and accordingly we will not be liable if this website is unavailable at any time. Where
                    possible we always try to give advance warning of maintenance issues that may
                    result in website down time but we shall not be obliged to provide such notice.
                </p>
                <h2>Eligibility</h2>
                <p>
                    You hereby declare that you are over 18 years of age, of sound mind and a person
                    resident in India as defined under Foreign Exchange Management Act, 1999.You
                    hereby declare that you are a human being and not a “bot”. 
                </p>
                <h2>License To Use Website</h2>
                <p>
                    You may view, download for caching purposes only, and print pages from the
                    website, provided that:
                </p>
                <p>
                    (a) You must not republish material from this website (including republication on
                    another website), or reproduce or store material from this website in any public or
                    private electronic retrieval system. (Though we encourage fair use for academic /
                    non-commercial purposes)
                </p>
                <p>
                    (b) You must not reproduce duplicate, copy, sell, resell, visit, or otherwise exploit our
                    website or material on our website for a commercial purpose.
                </p>
                <h2>Your Account</h2>
                <p>
                    You may be required to create an Account to use of the certain parts of the Website.
                    When you create an account, you might be required to login using Social Platforms
                    like Facebook and LinkedIn. We will also be generating some links which are
                    confidential and exclusively meant for you. You may not disclose these links to any
                    third party. If you learn of any unauthorized use of your links or account, please
                    contact us immediately.
                </p>
                <h2>Links</h2>
                <p>
                    This Website may contain links to websites maintained by non-affiliated third
                    parties. Such websites may have Terms of Use, Privacy Policies, or security practices
                    that are different from those of this Website. K. M. Global Financial Services Pvt Ltd
                    does not endorse or recommend, and hereby disclaims any liability for, any
                    information or products offered at such websites.
                </p>
                <h2>No Warranty</h2>
                <p>
                    Credit Fair Capital, K. M. Global Financial Services Pvt Ltd, does not warrant the
                    accuracy, adequacy or completeness of the information provided herein and
                    expressly disclaims liability for any errors or omissions in such information. No
                    warranty of any kind, implied, expressed or statutory including but not limited to the
                    warranties of non-infringement of third party rights, title, merchantability, fitness for
                    a particular purpose and freedom from computer virus, is given in conjunction with
                    the information. Calculators and tools on the Site provide you with ESTIMATES that
                    may be different than actual amounts.
                </p>
                <h2>Limitations Of Liability</h2>
                <p>
                    You agree that all access and use of the Website and its contents is at your own risk.
                    The information on this website is provided free-of-charge, and you acknowledge
                    that it would be unreasonable to hold us liable in respect of this website and the
                    information on this website.
                </p>
                <p>
                    Whilst we endeavour to ensure that the information on this website is correct, we
                    do not warrant its completeness or accuracy; nor do we not commit to ensuring that
                    the website remains available or that the material on this website is kept up-to-date.
                </p>
                <p>
                    Credit Fair Capital, K. M. Global Financial Services Pvt Ltd, (including its officers,
                    directors, employees, representatives, affiliates, and providers) will not be
                    responsible or liable for (a) any injury, death, loss, claim, act of god, accident, delay,
                    or any direct, special, exemplary, punitive, indirect, incidental or consequential
                    damages of any kind (including without limitation lost profits or lost savings),
                    whether based in contract, tort, strict liability or otherwise, that arise out of or is in
                    any way connected with (i) any failure or delay (including without limitation the use
                    of or inability to use any component of the website), or (ii) any use of the website or
                    content, or (iii) the performance or non-performance by Credit Fair Capital or any
                    provider or (b) any damages to or viruses that may infect my computer equipment or
                    other property as the result of my access to the website or my downloading of any
                    content from the website.
                </p>
                <h2>Forum Posting / Comments</h2>
                <p>
                    You must not use our website in any way that causes, or may cause, damage to the
                    website or impairment of the availability or accessibility of the website.
                </p>
                <p>
                    You must not use our website in any way which is unlawful, illegal, fraudulent or
                    harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or
                    activity.
                </p>
                <p>
                    You must not use our website for any purposes related to marketing without our
                    express written consent. You must not use our website to copy, publish or send mass
                    mailings or spam.
                </p>
                <p>
                    You must not use our website to copy, publish or send material which is illegal or
                    unlawful, or material which could give rise to legal action under applicable law. All
                    material you copy, publish or send via our website must not be defamatory,
                    obscene, indecent, hateful, discriminatory or inflammatory; such material must not
                    infringe any person’s intellectual property rights or rights of confidence, impinge
                    upon any person’s privacy, or constitute incitement to commit a crime; further,
                    material must not be misleading, deceptive, pornographic, threatening, abusive,
                    harassing or menacing.
                </p>
                <p>
                    We may take such action as we deem appropriate to deal with the posting of
                    unsuitable material, including suspending or cancelling your account, restricting your
                    access to our website, or commencing legal proceedings against you.
                </p>
                <h2>Other Term and Conditions</h2>
                <p>
                    You hereby confirm to register with you to avail a loan / credit using the services
                    provided by Credit Fair Capital.
                </p>
                <p>
                    You grant Credit Fair Capital a non-exclusive, transferable, sub-licensable, royalty-
                    free, worldwide license to use any information or content that you post on or in
                    connection with your use of the Website, subject to the privacy provisions described
                    in our privacy policy
                </p>
                <p>
                    You confirm that all information given to Credit Fair Capital (whether in Credit Fair
                    Capital’s online form or otherwise) is true, correct and not misleading. You agree to
                    provide any additional documents to Credit Fair Capital as may be required from
                    time to time
                </p>
                <p>
                    You agree that you will not use the Website for commercial purposes.
                </p>
                <p>
                    We may remove any content or information that you share on the Website for any
                    reason .
                </p>
                <p>
                    Certain services, such as accounting information depend on continuous connection
                    to the Credit Fair Capital database. Credit Fair Capital makes no assurance,
                    representation; promise whatsoever that such connectivity will always be available.
                </p>
                <p>
                    You hereby understand that when you apply for a loan, Credit Fair Capital or our
                    partner lenders may check your records amongst other things with its own records,
                    any information as available to Credit Fair Capital through any of its sources, those at
                    credit reference agencies (CRAs); those at fraud prevention agencies (FPAs) for
                    assessing your credit worthiness, verifying identity to prevent and detect crime and
                    money laundering.
                </p>
                <p>
                    Credit Fair Capital also has the right, and obligation, to report your details to credit
                    reference agencies (CRAs) in the event that you have delayed in repaying your loan
                    instalment.
                </p>
                <p>
                    Credit Fair Capital, has offered as privileged service to the User (without any charge).
                    However, Credit Fair Capital reserves the right to levy any service charges as
                    applicable from time to time in consideration for the services provided herein to the
                    user. In case user is not consenting to any service charges then, user may opt out of
                    Credit Fair Capital services .
                </p>
                <h2>Variation</h2>
                <p>
                    We may revise this disclaimer from time-to-time. Please check this page regularly to
                    ensure you are familiar with the current version.
                </p>
                <h2>Entire Agreement</h2>
                <p>
                    This disclaimer constitutes the entire agreement between you and us in relation to
                    your use of our website, and supersedes all previous agreements in respect of your
                    use of this website.
                </p>
                <h2>Laws and Jurisdiction</h2>
                <p>
                    The laws of India, without regard to its conflict of laws rules, will govern these
                    Terms. In case of any legal action courts located in Mumbai, India will have
                    jurisdiction over such matters. In any such action or any action we may initiate, the
                    prevailing party will be entitled to recover all legal expenses incurred in connection
                    with the legal action, including but not limited to costs, both taxable and non-
                    taxable, and reasonable attorney fees.
                </p>
                <p>
                    If any provision of the Terms of Use is found by a court of competent jurisdiction to
                    be invalid, the parties nevertheless agree that the court should endeavor to give
                    effect to the parties’ intentions as reflected in the provision, and the other
                    provisions of the Terms of Use remain in full force and effect. You agree that
                    regardless of any statute, regulation, or law to the contrary, any claim or cause of
                    action arising out of or related to use of the site or the Terms must be filed within
                    one (1) year after such claim or cause of action arose or be forever barred.
                </p>
                <p>
                    Please direct all questions or comments about these Terms of Use to
                    info@creditfaircapital.in
                </p>
            </div>
        </div>
    </div>
</div>
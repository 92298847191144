import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';
import { CryptoService } from '../services/crypto.service';
import { Logout } from 'src/app/store/actions/logout.action';
import { CookieService } from 'src/app/services/cookie.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  decryptedToken:any;
  token!:string;

  constructor(
    private authService : AuthService, 
    private cryptoService: CryptoService,
    private cookieService : CookieService)
    {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.requestToExclude(request.url)){
      // console.log("Token from localStorage",localStorage.getItem('token'))  
      const token = this.cookieService.getCookie('token')//localStorage.getItem('token');
        if (token) {
          this.decryptedToken =  this.cryptoService.decrypt(token);
          // console.log("Decrypted Token",this.decryptedToken);
          // If we have a token, we set it to the header
          request = request.clone({
              setHeaders: {'Authorization' : `Bearer ${this.decryptedToken}`}
          });
        }

        return next.handle(request).pipe( tap(() => {},
        (err: any) => {
          console.log('httpError', err)
          if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {                
              return;
              }
              this.authService.logoutUser();
          }
        }
      ));
    }
    return next.handle(request);
  }
  
  requestToExclude(resquestedUrl : any)
  {
    if(resquestedUrl.indexOf('auth') > 0 || resquestedUrl.indexOf('login') > 0 ){
      return true;
    }
    else{
      return false;
    }

  }

  // showAlertPopup() {
  //   if (this.dialogRef === undefined) {
  //     this.dialogRef = this.dialogService.showInfoPopup(Messages.ERROR_TITLE,Messages.SESSION_TIMEOUT);
  
  //     this.dialogRef.afterClosed().subscribe(result => {
  //       if (result && result.clickedOkay) {
  //         this.localStorageService.clearAll();
  //         this.router.navigateByUrl('login');
  //         this.dialogRef = undefined;
  
  //       }
  //     });
  //   }
  //}
}

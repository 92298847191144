<div class="close-Area">
    <mat-icon class="pull-right" mat-dialog-close>close</mat-icon>
</div>

<div class="success">
    <div class="text-center cpopup">
        <div>
            <!-- <img src="../../../assets/images/icons/Piggy Bank.png" width="100" height="100" />           -->
            <p class="mt-30 f-28 fw-900 addlh">Link expired or has already been used.</p>
            <p class="mt-30 f-26 addlh">To reset your password, return to the login page and select "Reset Password" to send a new email. </p>
        </div>
    </div>
    <!-- <div class="row action-buttons mt-30">
        <div class="col-md-6">
            <button mat-raised-button color="primary" type="button" class="f-18 cPointer">Ok</button>
        </div>
        <div class="col-md-6">
            <button mat-raised-button color="primary" class="f-18 cPointer" type="button">Cancel</button>
        </div>
    </div> -->
</div>

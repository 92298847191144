import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  setCookie(name : string, value : string){
    let date = new Date();  
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()}` 
    document.cookie = `${name}=${value}; path=/;${expires}`
    console.log('cokkies 1', document.cookie) 
  }

  getCookie(name : string){
    let cookies: Array<string> = document.cookie.split(';');
    let cookieName = `${name}=`;
    let cookie: string = '';

    for (let i: number = 0; i < cookies.length; i += 1) {
      cookie = cookies[i].replace(/^\s+/g, '');
        if (cookie.indexOf(cookieName) == 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return cookie;
  }

  deleteCookie(name : string){
    //this.setCookie(name, '',);
    document.cookie = `${name}=;expires=${new Date(0).toUTCString()};path=/`;
    console.log('cokkies 1', document.cookie) 
  }
}

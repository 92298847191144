<div class="upload" style="background-color: #fff !important;">
    <div class="row">
        <div class="col-md-12 pdfload">
            <!-- <iframe src="/assets/docs/privacy_policy.pdf" frameborder="0" class="iframestyle">
            </iframe> -->
            <div class="offset-lg-2 col-lg-8 section-part text-center">
                <h1>PRIVACY POLICY</h1>
            </div>
            <section id="content">

                <div class="container clearfix">
            
                    <div class="clear"></div>
            
                    <div class="container clearfix">
                        
                        <p>
                            <span style="font-weight: 400;">We, K.M. Global Financial Pvt Ltd., with our registered office at Surya Mahal 4th Floor, 5 Burjorji Bharucha Marg, Mumbai-400001, India (hereinafter shall be referred to as "
                                <b>Credit Fair Capital</b>
                                " or "
                                <b>Us</b>
                                " or "
                                <b>We</b>
                                " or “
                                <b>Our</b>
                                ”) are the owner and operator of the Platform.
                            </span>
                        </p>
                        <p>
                            <span style="font-weight: 400;">THIS POLICY DESCRIBES OUR POLICIES AND PROCEDURES FOR COLLECTION, USE, STORAGE, PROCESSING, DISCLOSURE AND PROTECTION OF ANY INFORMATION, INCLUDING, BUT NOT LIMITED TO, BUSINESS OR PERSONAL INFORMATION PROVIDED BY YOU AS A USER (“YOU/R”) WHILE USING THE PLATFORM.</span>
                        </p>
                        <p>
                            <span style="font-weight: 400;">USER SHALL MEAN ANY PERSON/ PERSONS, WHO VISITS, USES, DEALS WITH AND/ OR TRANSACTS THROUGH THE WEBSITE / APP OR AVAILS OUR SERVICES AND INCLUDES A BROWSER.</span>
                        </p>
                        <p>
                            <span style="font-weight: 400;">BY VISITNG/ ACCESSING THE PLATFORM, THROUGH THE WEBSITE OR THE APP AND VOLUNTARILY PROVIDING US WITH INFORMATION (PERSONAL AND / OR NON- PERSONAL), YOU ARE CONSENTING TO OUR USE OF IT IN ACCORDANCE WITH THIS POLICY.</span>
                        </p>
                        <p>
                            <span style="font-weight: 400;">THIS POLICY DOES NOT APPLY TO THIRD-PARTY WEBSITES/ APPLICATIONS THAT ARE CONNECTED VIA LINKS TO THE PLATFORM. IF YOU DO NOT AGREE WITH THIS POLICY, PLEASE DO NOT PROCEED FURTHER TO USE / ACCESS THIS PLATFORM.</span>
                        </p>
                        <p>
                            <span style="font-weight: 400;">THIS POLICY IS A PART OF THE PLATFORM <a href="https://creditfair.in/terms-conditions">TERMS &amp; CONDITIONS</a> AND ALL CAPITALIZED TERMS UNDER THIS POLICY THAT HAVE NOT BEEN SPECIFICALLY DEFINED HEREIN SHALL HAVE THE MEANING AS ASCRIBED TO IT UNDER THE PLATFORM TERMS &amp; CONDITIONS.</span>
                        </p>
                        <p>
                            <span style="font-weight: 400;">WE MAY UPDATE THIS POLICY FROM TIME TO TIME. WHILE WE WILL ALWAYS ENDEVOUR TO NOTIFY YOU, YOU MUST PERIODICALLY REVIEW THE POLICY FOR THE LATEST INFORMATION ON OUR PRIVACY PRACTICES.</span>
                        </p>
            
                        <p>
                        </p><ol type="1" class="leftmargin-sm">
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">COLLECTION OF INFORMATION</h4>
                                <span style="font-weight: 400;">You may scroll through our Website and generally view the contents of the Website without providing us any personal information. In such a case, We will place a Cookie on Your computer that will help us identity You should You decide to visit us again.</span>
                                <span style="font-weight: 400;">During Your use of the Platform, whether the App or the Website, We will collect certain NON-PERSONAL AND PERSONAL information from You.</span><br>
                                <ul class="leftmargin-sm tspd">
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">a.</span> The Non-Personal Information:</b> When You visit our Website, we typically collect the IP address of the device you use to connect to the internet, information such as what browser, browser version, operating system you have, the website you came from or the advertisement you viewed or clicked on. We also collect non-personal information on our Website and from third parties to provide an online experience that matches Your requirements.</li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">b.</span> The Personal Information:</b> Is collected by Us after You explicitly provide us consent or is shared by You with Us pursuant to a clear disclosure - such as name, address, phone number, and email address.<br>
                                        We will collect your personal information when you:
                                        <ul class="leftmargin-sm add-10">
                                            <li><span style="font-size: 1.3rem;
                                                font-weight: bold;">i.</span> Register on our Website or App through Your Facebook or Google account; and/or</li>
                                            <li><span style="font-size: 1.3rem;
                                                font-weight: bold;">ii.</span> Applying for a loan product or facility through our Platform.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <br>
                                <h5 class="font-weight-bold text-capitalize text-blue">Information that You provide to Us:</h5>
                                <ul class="leftmargin-sm">
                                    <li><b>Social Media: </b>We also collect information and data through Facebook or Google or other social media accounts of the Users which have been linked to the account on the Website / App. We may receive Your Personal Information from such social media websites/ applications, in accordance with the privacy policy of such sites/applications. Such information received from the other websites/platforms/applications at the time of accessing the Website / App may include, and not be limited to, the user name, profile picture, e-mail address associated with that particular website/ application, any information that has been made public in connection with that website/ application belonging to You and/ or any information or content the social media website/ application has the right to share with Us. By accessing or connecting to the Website / App / Platform through a social media website/ application, You authorize Us to collect, store, use and retain such information and content in accordance with this Policy. This information provides your activities off Credit Fair Capital - including information about your device, websites you visit, purchases you make, the ads you see, how you use their services, and other data resembling.</li>
                                    <li>Your name, address, date of birth, contact information, billing information, marital status, PAN number, Demat proofs, name of the bank where You have a primary account, bank statements, pay slips, information relating to income and expenses obtained through bank SMS scraping and through integration with our service provider, etc.<br>
                                        
                                    </li>
                                    <li>Your Aadhar Number on behalf of the financial lending partners, at your sole discretion</li>
                                    <li>Other information that You provide when You write directly to Us (including by e-mail) or provide Us over telephone or while filling out any applications on the Platform.</li>
                                </ul>
                                <br>
                                <h5 class="font-weight-bold text-capitalize text-blue">Information we collect from Your Device or a third Party:</h5>
                                <span style="font-weight: 400;">We collect information from the device when you download and install the App and <b>explicitly seek permissions from YOU to get the required information from the device</b>.
                                    The information we collect from your device includes the unique ID i.e. IMEI number, information on operating system, SDK version and list of installed apps, contact, sms, camera, storage, location.
                                    We collect information about your device to provide automatic updates and additional security so that your account is not used in other people’s devices.
                                    In addition, the information provides us valuable feedback on your identity as a device holder as well as your device behavior, thereby allowing us to improve our services and provide an enhanced customized user experience to you.<br> The key information collected from the device is further detailed below</span>
                                <ul class="leftmargin-sm" style="padding-left: 20px;">
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">a.</span> MOBILE NUMBER AND EMAIL ADDRESS </b><br> <div style="margin-left: 20px;">When you register with us, we collect your mobile number and email address and validate the some via OTP to uniquely authenticate and identify you and your loan application, This helps us to ensure that no unauthorised device or person is acting on your behalf.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">b.</span> SOCIAL MEDIA </b><br><div style="margin-left: 20px;">Our App collects your public profile data depending on the social media or networking platform used, like Facebook/Google to login to our App, The information collected from your social media networking account shall be clearly displayed to you at the time you log-in to our App with your consent.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">c.</span> DEVICE INFORMATION </b><br><div style="margin-left: 20px;">Our app collects and monitors information about your device including your hardware model, operating system information, unique device identifiers like IMEI or serial numbers, to offer you customized services and also to uniquely identify the devices to ensure that unauthorized devices are not able to act on your behalf and to mitigate the fraud.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">d.</span> INSTALLED APPLICATIONS</b><br><div style="margin-left: 20px;">Our App collects metadata information which includes the application name, package name, installed time, updated time, version name and version code of each installed application on your device to perform credit risk assessment and mitigate organised frauds.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">e.</span> CONTACTS</b><br><div style="margin-left: 20px;">Our App collects and monitors your device contacts information to detect valid references and to autofill the data during your loan application process.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">f.</span> LOCATION</b><br><div style="margin-left: 20px;">Our App collects and monitors the information about the location of your device to provide timely notifications about availability of services in your location, to reduce risk associated with your loan application and expedite current address and KYC validation process.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">g.</span> CAMERA</b><br><div style="margin-left: 20px;">Our App requires camera access to easily scan and capture your profile photograph, KYC and income related documents and provide better user experience by auto-filling the relevant document data.</div></li>
                                    <li><b><span style="font-size: 1.3rem;
                                        font-weight: bold;">h.</span> STORAGE</b><br><div style="margin-left: 20px;">Our App requires storage permission so that your KYC and other loan application related scanned documents can be securely downloaded and saved on to your phone, You then can easily upload the right KYC related documents for quick submission of loan application, enhancing the user experience.</div></li>
                                </ul>
                                <br>
                                <h5 class="font-weight-bold text-capitalize text-blue">From Third Parties:</h5>
                                <span style="font-weight: 400;">We also collect your credit reports and credit data on your behalf or on behalf of our financial partner in the event you have applied for a loan / facility to the said financial partner. In this regard, You:</span>
                                <ul class="leftmargin-sm">
                                    <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">i.</span> </div><div style="margin-left: 10px;">Authorize Us, to request and receive Your credit score / report from credit information companies, now and on a periodic basis, at its discretion until such time You communicate in writing to Us to discontinue such requests.</div></li>
                                    <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">ii.</span> </div><div style="margin-left: 7px;">Further fully understand that the purpose of this credit score / report is to enable Us to make informed lending decisions effectively and enable faster processing of credit applications to help provide speedier access to credit through our Platform.</div></li>
                                    <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">iii.</span></div><div style="margin-left: 4px;"> Also understand that We may apply for the credit score / report either ourselves directly or through any of their partner tie ups who are members of credit information companies, and fully authorize Us to share my details with such partner.</div></li>
                                </ul>
                                <br>
                                <h5 class="font-weight-bold text-capitalize text-blue">Other Information:</h5>
                                <ul class="leftmargin-sm">
                                    <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">i.</span></div><div style="margin-left: 10px;">Any other information that Credit Fair Capital is required to collect as per specific mandate from any financial lending partners or as a requirement under applicable law;</div></li>
                                     <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">i.</span></div><div style="margin-left: 10px;">Information from You electronically - like the pages viewed, how You navigate through the Website / Platform and interact with webpages, etc.</div></li>
                                     <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">iii.</span></div><div style="margin-left: 3px;"><b>Cookies:</b> Cookies are small data files that a Website / App stores on Your computer. We will use cookies on our Website / App similar to other lending websites / apps and online marketplace websites / apps. Use of this information helps Us identify You in order to make our Platform more user friendly. We might also use this information to display advertising from third party companies. Most browsers will permit You to decline cookies but if You choose to do this it might affect service on some parts of Our Website.</div></li>
                                     <li style="display: flex;"><div style="margin-left: 20px;"><span style="font-size: 1.3rem;
                                        font-weight: bold;">iv.</span></div><div style="margin-left: 10px;">We might also use other technology to track how You interact with Platform and employ some third-party agencies to use the data on Our behalf.</div></li>
                                </ul>
                                <br><br>
                                <span>The information We collect about You will depend on the products and services We offer or You avail, on an ongoing basis. The information collected from You will be used in order to effectively provide Services to You. If You do not allow Us to collect all the information We request on the Platform, We may not be able to deliver all of these services effectively.</span>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">PURPOSE, USE AND DISCLOSURE OF INFORMATION</h4>
                                <span style="font-weight: 400;">We will use and retain Your information for such periods as necessary to provide You the services on our Platform, to comply with our legal obligations, to resolve disputes, and enforce our agreements with you or the financing partners who have advanced a loan to You</span><br>
                                <h5 class="font-weight-bold text-capitalize text-blue">Use of Information:</h5>
                                <span style="font-weight: 400;">We use data collected to support our Product, Credit Fair Capital, as a platform assists You to reach and select diversity of third-party financial partners, along with their products, and technically help you accomplish transaction with these third-party financial partners.<br>We may use the information provided by You in the following ways:</span>
                                <ul class="leftmargin-sm" style="padding-left: 20px;">
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">a.</span></div> <div style="margin-left: 10px;"> to establish identity and verify the same;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">b.</span></div> <div style="margin-left: 10px;"> monitor, improve and administer our Platform;</div></li>
                                   <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                    font-weight: bold;">c.</span></div> <div style="margin-left: 10px;"> design and offer customized products and services offered by our third-party financial partners;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">d.</span></div> <div style="margin-left: 10px;"> analyze how the Platform is used, diagnose service or technical problems and maintain security;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">e.</span></div> <div style="margin-left: 10px;"> send communications notifications, information regarding the products or services requested by You or process queries and applications that You have made on the Platform;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">f.</span></div> <div style="margin-left: 10px;"> manage Our relationship with You and inform You about other products or services We think You might find of some use;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">g.</span></div> <div style="margin-left: 10px;"> conduct data analysis in order to improve the Services provided to the User;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">h.</span></div> <div style="margin-left: 10px;"> use the User information in order to comply with country laws and regulations;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">i.</span></div> <div style="margin-left: 10px;"> to conduct KYC for our third-party lending partners based on the information shared by the User for the provision of Services;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">j.</span></div> <div style="margin-left: 10px;"> use the User information in other ways permitted by law to enable You to take financial services from our lending partners.</div></li>
                                </ul>
                                <br>
                                <h5 class="font-weight-bold text-capitalize text-blue">Disclosure of Information:</h5>
                                <span style="font-weight: 400;">We may share Your information with third parties including our regulated financial partners for provision of services on the Platform and/or for facilitation of a loan / facility to a User. We may also share Your information with third parties under a confidentiality agreement for provision of services which inter alia restricts such third parties from further disclosing the information unless such disclosure is for the purpose as detailed under that confidentiality agreement.<br>We will share Your information with third parties only in such manner as described below:</span>
                                <ul class="leftmargin-sm" style="padding-left: 20px;">
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">a.</span></div> <div style="margin-left: 10px;"> We may disclose Your information to the financial service providers, banks or NBFCs and Our third-party partners for providing the Services as detailed under the Terms &amp; Conditions;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">b.</span></div> <div style="margin-left: 10px;"> We may share Your information with our third-party partners in order to conduct data analysis in order to serve You better and provide Services on our Platform;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">c.</span></div> <div style="margin-left: 10px;">We may disclose Your information, without prior notice, if We are under a duty to do so in order to comply with any legal obligation or an order from the government and/or a statutory authority, or in order to enforce or apply Our terms of use or assign such information in the course of corporate divestitures, mergers, or to protect the rights, property, or safety of Us, Our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</div></li>
                                   <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                    font-weight: bold;">d.</span></div> <div style="margin-left: 10px;"> We may also share the data / information with our technology service providers to perform credit and know your customer checks to facilitate a loan for the User through regulated financial partner;</div></li>
                                    <li style="display: flex;"><div><span style="font-size: 1.3rem;
                                        font-weight: bold;">e.</span></div> <div style="margin-left: 10px;">We and our affiliates may share Your information with another business entity should we (or our assets) merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business for continuity of business.</div></li>
                                </ul>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">STORAGE OF INFORMATION</h4>
                                <span style="font-weight: 400;">You further understand and agree that disbursement of a loan or approval of a credit card by a financial partner does not automatically rescind the consents you have provided to us under the Privacy Policy nor does deletion of your account with the Platform. Any information provided to us is retained by Us until You have an account based / active relationship Us or with a financial partner.</span>
                                <span style="font-weight: 400;">We store and process Your personal information on secure AWS cloud servers and other cloud service providers within India. Some of the safeguards We use are firewalls and encryption using SSL, and information access authorization controls. We use reasonable safeguards to preserve the integrity and security of Your information against loss, theft, unauthorized access, disclosure, reproduction, use or amendment. To achieve the same, We use reasonable security practices and procedures as mandated under applicable laws for the protection of Your information. Information You provide to Us may be stored on Our secure servers located within or outside India.</span>
                                <span style="font-weight: 400;">However, You understand and accept that there’s no guarantee that data transmission over the Internet will be completely secure and that any information that You transmit to Us is at Your own risk. We assume no liability for any disclosure of information due to errors in transmission, unauthorized third party access to our Platform and data bases or other acts of third parties, or acts or omissions beyond Our reasonable control and You shall not be entitled to hold Credit Fair Capital responsible for any breach of security.</span>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">THIRD PARTY WEBSITES</h4>
                                <span style="font-weight: 400;">With this Policy We’re only addressing the disclosure and use of data collected by Us. If You visit any websites through the links on the Credit Fair Capital Platform, please ensure You go through the privacy policies of each of those websites. Their data collection practices and their policies might be different from this Policy and Credit Fair Capital does not have control over any of their policies neither does it have any liability in this regard.</span>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">PHISHING</h4>
                                <span style="font-weight: 400;">"<b>Phishing</b>" usually occurs when users of a website are induced by an individual/entity into divulging sensitive personal data by using fraudulent websites and/ or e-mail addresses. In the event You provide information to a website or respond to an e-mail which does not belong to Us or is not connected with Us in any manner, You will be a victim to Phishing. We do not send e-mails requesting a user for payment information, user name or passwords. However, We may verify the user name, password etc. provided by You from time to time.</span>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">SEVERABILITY AND EXCLUSION</h4>
                                <span style="font-weight: 400;">We have taken every effort to ensure that this Policy adheres with the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy. This Policy does not apply to any information other than the information collected by Credit Fair Capital through the Platform. This Policy shall be inapplicable to any unsolicited information You provide Us through the Platform or through any other means. This includes, but is not limited to, information posted in any public areas of the Website / App. All unsolicited information shall be deemed to be non-confidential and the Company shall be free to use and/ or disclose such unsolicited information without any limitations.</span>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">NO WAIVER</h4>
                                <span style="font-weight: 400;">The rights and remedies of available under this Policy may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.</span>
                            </li><br><br>
                            <li style="font-weight: 400;">
                                <h4 class="font-weight-bold text-capitalize text-blue">GREVIANCE OFFICER</h4>
                                <span style="font-weight: 400;">If You require any information or clarification regarding the use of Your personal information or this Privacy Policy, You can contact us at <b>info@creditfaircapital.in</b></span><br><br>
                                <span style="font-weight: 400;">The Company is a company based in India. By browsing/using the Platform, You hereby acknowledge that the Company is not responsible or liable in any manner to comply with any local laws of Your territory except India.</span>
                            </li>
                            <br><br>
                        </ol>
                        <p></p>
                    </div>
                </div>
            </section>>
        </div>
    </div>
</div>
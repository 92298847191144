import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {of} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { Subscriber } from 'rxjs/internal/Subscriber';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Logout } from 'src/app/store/actions/logout.action';
import { CookieService } from 'src/app/services/cookie.service';

@Injectable({providedIn : 'root'})
export class AuthService {
  
	private GENERATE_OTP = 'service/generate-otp';
	private VERIFY_OTP = 'service/verify-otp';
	private LOGIN = 'service/verify-password';
	private FORGOT_PASSWORD = 'service/forgot-password';
	private RESET_PASSWORD = 'service/submit-password';
	private USER_INFO = 'api/get-user';
	private FETCH_TOKEN = '';

	private Token: string = '';
	private Access_Token: string = '';
	private readonly REFRESH_TOKEN: string = 'refreshToken';
	private User : any;
  onUserUpdate : Subject<any> = new Subject();
  showOTP:any = new Subject<any>();
  
  constructor(
		private http: HttpClient, 
		private store : Store, 
		private router : Router,
	  private cookieService : CookieService) { }

  generateOTP(mobileOrEmail: string,type :any,){
    const data = {
      phone: mobileOrEmail,
      type: type
    }
    return this.http.post(environment.base_url + this.GENERATE_OTP, data);
	}
	
  verifyOTP(mobileOrEmail: string, otp : string,type :any,ref_code : any){
    const data = {
      phone: mobileOrEmail,
			type: type,
			otp : Number(otp),
			referred_by:ref_code
    }
    return this.http.post(environment.base_url + this.VERIFY_OTP, data);
	}

  loginUser(data: any){    
    return this.http.post(environment.base_url + this.LOGIN, data);
	}	

	loginUser1(data : any){
    return this.http.post(environment.base_url + this.LOGIN, data);
	}
  
  fetchUser(){
    return this.http.get(`${environment.base_url}${this.USER_INFO}`);
	}

	forgotPassword(data: any){    
    return this.http.post(environment.base_url + this.FORGOT_PASSWORD, data).toPromise();
	}
  
	resetPassword(data: any){    
    return this.http.post(environment.base_url + this.RESET_PASSWORD, data).toPromise();
	}

	public get user(){
		return this.User;
	}

	public setUser(user : any){
		this.User = user;
		this.onUserUpdate.next(user);
	}

	public get token() {
		return this.Token;
  }

  public setTokens(data : any) {
		// console.log('setTokens == > ', data);
		//const refreshToken = data.RefreshToken;
		// localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
		// console.log('setTokens data', data);
		this.Token = data.IdToken;
		this.Access_Token = data.AccessToken
		localStorage.setItem(
			'refreshToken',
			data.RefreshToken
		)
		// console.log(' this.Token == > ', this.Token);
	}
  
	getTokens(refresh_token: string): Observable<any> {		
		const headers =  { 'Authorization': this.Token };
		const data = {
      refresh_token: refresh_token
    }
		return this.http.post(environment.base_url + this.FETCH_TOKEN, data, {headers});
	}

	public refreshToken(minutes: number) {
		return new Observable<any>(
			(subscriber : Subscriber<any>) => {
				interval(1000 * 60 * minutes).subscribe((x : any) => {
					const refreshToken: any = localStorage.getItem(this.REFRESH_TOKEN);
					// console.log('refresh_tok en ==', refreshToken);
					this.getTokens(refreshToken).subscribe((response : any) => {
						subscriber.next(response);
					});
				});
			}
		);
	}

	public startTokenUpdateTimer(time: number) {
		this.refreshToken(time).subscribe((data : any) => {
			// const token = {
      //           IdToken: data.id_token,
      //           RefreshToken: data.RefreshToken
      //       }
			// console.log('startTokenUpdateTimer', token);
			this.setTokens(data);
		});
	}

	logoutUser(){
		this.store.dispatch(new Logout());
		this.cookieService.deleteCookie('token')
		localStorage.clear();
		this.router.navigateByUrl('/auth/login');
	}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/store/actions/logout.action';
import { UtilsService } from 'src/app/services/utils.service';
import { SharedService } from '../services/shared.service';
import { CookieService } from 'src/app/services/cookie.service';
import { OnboardingService } from '../modules/onbording/services/onboarding.service';
import { CryptoService } from 'src/app/services/crypto.service';
import { ModalWindowService } from 'src/app/modules/shared/modal-window/modal-window-service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  hideElement = true;
  hideOnboard = true;
  hideInvestorButton : boolean = true;
  hideInvestNowButton : boolean = true;
  loggedIn : boolean = false; 
  username : string = '';
  responseData: any;
  currentURL: any = '';
  
  constructor(private router : Router,
    private onboardingService: OnboardingService,
    private store: Store,
    private utilsService : UtilsService, private sharedService : SharedService,
    private cookieService : CookieService, private cryptoService:CryptoService,private modalService: ModalWindowService) {
   this.router.events.subscribe((event) => {
      let routes = ['/', '/auth/login', '/blank', '/privacy-policy', '/tnc', '/reset-password']
      let onboarding = ['/onboarding']
      if (event instanceof NavigationEnd) {
        this.hideElement = (routes.includes(event.url) || event.url.includes('/reset-password')) ? true : false       
        // console.log(event.url);
            if(event.url.indexOf('/auth/login') !== -1)
            {
              this.hideElement = true;
            }
          
          }
          if(event instanceof NavigationEnd){
            this.hideOnboard = (onboarding.includes(event.url))? true: false
          }
          if(this.hideOnboard){
            this.hideInvestorButton = this.hideInvestNowButton = true;
          }
          else{
            if(window.location.href.includes('ifa'))
            {
              this.hideInvestorButton = false;
            }
            if(!window.location.href.includes('ifa'))
            {
              this.hideInvestNowButton = false;
            }
          }
          
        })
     }

  ngOnInit(): void {       
    
    this.utilsService.onUserUpdate.subscribe((data : any) => {
      this.username = data.username;
      this.currentURL = data.referral_link;
    });

    if(this.username == ''){
      this.username = localStorage.getItem('username') || '';
    }
    if(this.currentURL == ''){
      let refUrl = localStorage.getItem('referral_link') || '';      
      this.currentURL = refUrl ? this.cryptoService.encrypt(refUrl) : refUrl;
    }
  }

  logout(){
    this.store.dispatch(new Logout());    
    this.cookieService.deleteCookie('token')
    localStorage.clear();
    this.router.navigateByUrl('/auth/login')
  }
  addInvestor(){
    const url = this.currentURL; 
    window.open(url, '_blank');
    this.sharedService.accordianOpenHandler.next(true);
  }
  
  investNow(){
    this.router.navigateByUrl('/dashboard/invest/');
  }
}

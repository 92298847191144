import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from 'src/environments/environment'; 
import * as LogOutActions from "../actions/logout.action";



export interface IAppState { }
export interface State {}

export const reducers: ActionReducerMap<State> = {

};

  
export function clearState(reducer : ActionReducer<IAppState>) {
  return function (state : any, action : any) {

    if (action.type === LogOutActions.Logout) {
      state = undefined;
    }

    return reducer(state, action);
  };
}


export const metaReducers: MetaReducer<State>[] = !environment.production ? [clearState]
: [clearState];
